@import url("https://fonts.googleapis.com/css?family=Inconsolata:700&display=swap");
@font-face {
    font-family: 'monacoregular';
    src: url('monaco-webfont.woff2') format('woff2'),
         url('monaco-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
:root {
    --main-bg-color: #004b8d;
    --header-footer: rgb(0, 49, 92);
    --gradient-background: linear-gradient(147deg, #7abaf2 0%, #0074d9 74%);
    --title: #7abaf2;
    --menu-active: #5ec7f2;
}
html {
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    position: relative;
    margin: 0;
    min-height: 100%;
    background-color: var(--main-bg-color);
}

* {
    font-family: 'monacoregular', monospace;
    color: #fff;
}

::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.45rem;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(145, 145, 145, 0.3);
    -webkit-border-radius: 1rem;
    box-shadow: inset 0 0 6px rgba(145, 145, 145, 0.3);
    border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    background-color: rgba(124, 124, 124, 0.6);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #babac0;
}

::-webkit-scrollbar-button {
    display: none;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
}

.header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
}

.active {
    border-bottom: 0.05rem solid var(--menu-active);
    color: var(--menu-active) !important;
}

.nav-item {
    margin: 1rem;
}

.nav-item:hover {
    border-bottom: 0.05rem solid #fff;
}

.logo-img {
    height: 4rem;
}

.center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-header {
    height: 40vh;
    background-color: var(--header-footer);
}

h3,
h4 {
    color: var(--title);
    font-family: "Inconsolata", monospace;
    font-weight: 700;
    margin-bottom: 1rem;
}

a,
a:hover {
    color: var(--title);
}

.footer {
    position: sticky;
    top: 100%;
    background-color: var(--header-footer);
    padding: 2rem 0;
    width: 100%;
}

.bg-dark {
    background-color: var(--header-footer) !important;
}

.logoOgr {
    display: flex;
}

.logoOgr__item:not(:last-child) {
    margin-right: 1rem;
}

.logoOgr__item {
    position: relative;
}

.logoOgr__item:not(:first-child) {
    padding-left: 1rem;
}

.logoOgr__item:not(:first-child)::before {
    content : "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0 0 0 1px;
    height: 70%;
    margin: auto;
}

.logoOgr__title {
    color: white;
    margin-bottom: 0.5rem;
}

.footer__content {
    display: flex; justify-content: space-between;
}

.footer__col__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container-cus {
        padding: 2rem 1rem !important;
    }
    .ourteam {
        border-bottom-right-radius: 5rem !important;
    }
    .ourteam__content {
        flex-direction: column-reverse !important;
    }
    .ourteam__img {
        width: 90vw !important;
        margin-bottom: 2rem;
        margin-left: 0 !important;
    }
    .activity__content {
        grid-template-columns: 1fr !important;
        margin-top: 2rem !important;
    }
    .footer__content {
        flex-direction: column !important;
        align-items: center;
    }
    .footer__col__right {
        flex-direction: column-reverse;
        align-items: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container-cus {
        padding: 3rem 1rem !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ourteam {
        margin-right: 10rem;
    }
    .ourteam__content {
        padding-left: 6rem !important;
    }
}

.sponsors__logo {
    object-fit: contain;
    background: white;
    padding: 5px;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    margin-right: 10px;
}

.ourteam {
    background-color: rgba(0, 49, 92, 0.5);
    border-top-right-radius: 100rem;
    border-bottom-right-radius: 100rem;
}

.ourteam__content {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.ourteam__img {
    width: 40vw;
    margin-left: 2rem;
    object-fit: contain;
}

.ourteam__button {
    background-image: var(--gradient-background);
    color: white;
    padding: 0.6rem 1rem;
    border-radius: 3rem;
}

.ourteam__content a:hover {
    color: white;
    text-decoration: none;
}

.activity__img {
    height: 50vh;
    width: 100%;
    object-fit: contain;
}

.activity__content {
    text-align: left;
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 3rem;
    margin: 4rem 0;
}

.activity__content__item__icon {
    width: 3em;
    padding: 0.3em;
    object-fit: contain;
    margin-bottom: 0.5rem;
    border-radius: 3em;
    -webkit-animation: glowing 3000ms infinite;
    -moz-animation: glowing 3000ms infinite;
    -o-animation: glowing 3000ms infinite;
    animation: glowing 3000ms infinite;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 3px #3dadf2;
    }
    50% {
        box-shadow: 0 0 50px #020f59;
    }
    100% {
        box-shadow: 0 0 3px #3dadf2;
    }
}

.footer__social__icon {
    height: 1rem;
    width: 1rem;
    object-fit: contain;
    margin: 0 0.2rem;
    filter: grayscale(1);
    border-radius: 5px;
    background-color: gray;
    object-position: center;
}

.social:hover {
    text-decoration: none;
}
