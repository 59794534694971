@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800");
.member {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    display: grid;
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 1rem;
}
.text {
    -webkit-line-clamp: 8;
}
.member-card {
    background-repeat: no-repeat;
    display: block;
    border-radius: 8px;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    background-position: 50% 0%;
    background-size: cover;
}
.member-card__overlay {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
        to bottom,
        rgba(42, 159, 255, 0.2) 0%,
        #212120 100%
    );
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.member-card__share {
    padding: 1em;
    display: inline-block;
    width: 100%;
    max-width: 130px;
    display: block;
    width: 100%;
}
.member-card__icon {
    color: #ffffff;
    mix-blend-mode: lighten;
    opacity: 0.4;
    padding: 0;
    background-color: transparent;
    border: transparent;
    font-size: 3rem;
}
.member-card__icon:hover {
    opacity: 1;
    mix-blend-mode: lighten;
}
.member-card__icon:not(:first-of-type) {
    margin-left: 5px;
}
.member-card__icon i {
    font-size: 1.2em;
}
.member-card__content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: relative;
    float: right;
    padding-right: 1.2em;
    padding-bottom: 1em;
    margin-top: 5rem;
    float: inherit;
    padding: 0 1em 1em;
}
.member-card__header {
    margin-bottom: 1em;
}
.member-card__title {
    color: #ffffff;
    margin-bottom: 0.25em;
    opacity: 0.75;
    margin-top: 10rem;
}
.member-card__info {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8em;
    color: #2a9fff;
    line-height: 1;
    margin: 0;
    font-weight: 700;
    opacity: 0.5;
}
.member-card__desc {
    font-weight: 300;
    opacity: 0.84;
    text-align: justify;
}

h1,
h2 {
    font-family: "Montserrat", helvetica, arial, sans-serif;
    letter-spacing: 2px;
    line-height: 1;
    font-weight: 400;
}

h3 {
    text-transform: uppercase;
    font-size: 2em;
    margin: 3rem 0;
}

.btn {
    padding: 0.5rem 2rem;
    background-color: rgba(255, 255, 255, 0.4);
    color: white;
}

.btn-outline {
    background-color: transparent;
    border: 3px solid #ffffff;
}

.btn::before {
    font-family: "Material Icons";
    content: "\e037";
    vertical-align: middle;
    font-size: 1.5em;
    padding-right: 0.5em;
}

.btn-outline:hover {
    border-color: #2a9fff;
    color: #2a9fff;
    box-shadow: 0px 1px 8px 0px rgba(245, 199, 0, 0.2);
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}
.overlay:target {
    visibility: visible;
    opacity: 1;
}

.popup {
    margin: 5rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 5px;
    width: 40%;
    position: relative;
    transition: all 1s ease-in-out;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.popup .close:hover {
    color: var(--main-bg-color);
}
.popup .content {
    max-height: 40%;
    overflow: auto;
    color: var(--header-footer);
    text-align: justify;
}

@media screen and (max-width: 700px) {
    .box {
        width: 70%;
    }
    .popup {
        width: 70%;
    }
}

.social:hover {
    text-decoration: none;
}

.social {
    padding-right: 10px;
}
